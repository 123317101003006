import request from './api.js'
// 登录
export function login(data) {
    return request({
        url: 'api/login/login',
        method: 'post',
        data,
    })
}
// 新增地址
export function add_address(data) {
    return request({
        url: 'api/member_address/add_address',
        method: 'post',
        data,
    })
}
// 获取用户地址
export function getuserarea(params) {
    return request({
        url: 'api/member_address/index',
        method: 'get',
        params,
    })
}