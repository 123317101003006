import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'inquiry',
        component: () =>
            import ('../views/inquiry/index.vue'),
        meta: { name: '问诊' }
    },
    {
        path: '/inquiry',
        name: 'inquiry',
        component: () =>
            import ('../views/inquiry/index.vue'),
        meta: { name: '问诊' }
    },
    {
        path: '/inquirylist',
        name: 'inquirylist',
        component: () =>
            import ('../views/inquiry/inquirylist.vue'),
        meta: { name: '问诊' }
    },
    {
        path: '/inquirying',
        name: 'inquirying',
        component: () =>
            import ('../views/inquiry/inquirying.vue'),
        meta: { name: '问诊' }
    },
    // {
    //     path: '/fund',
    //     name: 'fund',
    //     component: () =>
    //         import ('../views/fund/index.vue'),
    //     meta: { name: '基金' }
    // },
    {
        path: '/my',
        name: 'my',
        component: () =>
            import ('../views/my/index.vue'),
        meta: { name: '我的' }
    },
    {
        path: '/reception',
        name: 'reception',
        component: () =>
            import ('../views/my/reception.vue'),
        meta: { name: '待问诊' }
    },
    {
        path: '/myinquiry',
        name: 'myinquiry',
        component: () =>
            import ('../views/my/myinquiry.vue'),
        meta: { name: '我的问诊' }

    },
    {
        path: '/mydoctor',
        name: 'mydoctor',
        component: () =>
            import ('../views/my/mydoctor.vue'),
        meta: { name: '我的私人医生' }

    },
    {
        path: '/doctorinfo',
        name: 'doctorinfo',
        component: () =>
            import ('../views/my/doctorinfo.vue'),
        meta: { name: '私人医生详情' }

    },
    {
        path: '/myaddress',
        name: 'myaddress',
        component: () =>
            import ('../views/my/myaddress.vue'),
        meta: { name: '我的地址' }

    },
    {
        path: '/editaddress',
        name: 'editaddress',
        component: () =>
            import ('../views/my/editaddress.vue'),
        meta: { name: '编辑地址' }

    },
    {
        path: '/myinfo',
        name: 'myinfo',
        component: () =>
            import ('../views/my/myinfo.vue'),
        meta: { name: '我的资料' }

    },
    {
        path: '/set',
        name: 'set',
        component: () =>
            import ('../views/my/set.vue'),
        meta: { name: '设置' }

    },
    {
        path: '/message',
        name: 'message',
        component: () =>
            import ('../views/my/message.vue'),
        meta: { name: '我的消息' }

    },
    {
        path: '/hitdistribution',
        name: 'hitdistribution',
        component: () =>
            import ('../views/my/hitdistribution.vue'),
        meta: { name: '我的急配' }

    },
    {
        path: '/hitdistributioninfo',
        name: 'hitdistributioninfo',
        component: () =>
            import ('../views/my/hitdistributioninfo.vue'),
        meta: { name: '急配详情' }

    },
    {
        path: '/help',
        name: 'help',
        component: () =>
            import ('../views/my/help.vue'),
        meta: { name: '我的求助' }

    },
    {
        path: '/helpinfo',
        name: 'helpinfo',
        component: () =>
            import ('../views/my/helpinfo.vue'),
        meta: { name: '求助详情' }

    },
    {
        path: '/orderfrom',
        name: 'orderfrom',
        component: () =>
            import ('../views/my/orderfrom.vue'),
        meta: { name: '我的订单' }

    },
    {
        path: '/orderinfo',
        name: 'orderinfo',
        component: () =>
            import ('../views/my/orderinfo.vue'),
        meta: { name: '我的订单' }

    },
    {
        path: '/collect',
        name: 'collect',
        component: () =>
            import ('../views/my/collect.vue'),
        meta: { name: '我的收藏' }

    },
    {
        path: '/article',
        name: 'article',
        component: () =>
            import ('../views/inquiry/article.vue'),
        meta: { name: '文章详情' }

    },
    {
        path: '/hospitallist',
        name: 'hospitallist',
        component: () =>
            import ('../views/inquiry/finddoctor/hospitallist.vue'),
        meta: { name: '医院列表' }

    },
    {
        path: '/hospitalindex',
        name: 'hospitalindex',
        component: () =>
            import ('../views/inquiry/finddoctor/hospitalindex.vue'),
        meta: { name: '医院主页' }

    },
    {
        path: '/hospitalinfo',
        name: 'hospitalinfo',
        component: () =>
            import ('../views/inquiry/finddoctor/hospitalinfo.vue'),
        meta: { name: '医院概况' }

    },
    {
        path: '/doctorlist',
        name: 'doctorlist',
        component: () =>
            import ('../views/inquiry/finddoctor/doctorlist.vue'),
        meta: { name: '医生列表' }

    },
    {
        path: '/appointment',
        name: 'appointment',
        component: () =>
            import ('../views/inquiry/finddoctor/appointment.vue'),
        meta: { name: '预约信息' }

    },
    {
        path: '/doctoruser',
        name: 'doctoruser',
        component: () =>
            import ('../views/inquiry/finddoctor/doctoruser.vue'),
        meta: { name: '医生详情' }

    },
    {
        path: '/privatedoctor',
        name: 'privatedoctor',
        component: () =>
            import ('../views/inquiry/privatedoctor/privatedoctor.vue'),
        meta: { name: '私人医生' }

    },
    {
        path: '/privatedoctororde',
        name: 'privatedoctororde',
        component: () =>
            import ('../views/inquiry/privatedoctor/privatedoctororde.vue'),
        meta: { name: '提交订单' }

    },
    {
        path: '/privatedoctorordeinfo',
        name: 'privatedoctorordeinfo',
        component: () =>
            import ('../views/inquiry/privatedoctor/privatedoctorordeinfo.vue'),
        meta: { name: '订单详情' }

    },
    {
        path: '/subsequent',
        name: 'subsequent',
        component: () =>
            import ('../views/inquiry/subsequent/subsequent.vue'),
        meta: { name: '复诊' }

    },
    {
        path: '/ambulance',
        name: 'ambulance',
        component: () =>
            import ('../views/inquiry/ambulance/ambulance.vue'),
        meta: { name: '救护车' }

    },
    {
        path: '/fund',
        name: 'fund',
        component: () =>
            import ('../views/inquiry/fund/fund.vue'),
        meta: { name: '基金' }

    },
    {
        path: '/fundinfo',
        name: 'fundinfo',
        component: () =>
            import ('../views/inquiry/fund/fundinfo.vue'),
        meta: { name: '基金详情' }

    },
    {
        path: '/applyfor',
        name: 'applyfor',
        component: () =>
            import ('../views/inquiry/fund/applyfor.vue'),
        meta: { name: '基金申请' }

    },
    {
        path: '/Quickmatch',
        name: 'Quickmatch',
        component: () =>
            import ('../views/inquiry/Quickmatch.vue'),
        meta: { name: '急配申请' }

    },
    {
        path: '/pharmacy',
        name: 'pharmacy',
        component: () =>
            import ('../views/pharmacy/index.vue'),
        meta: { name: '药房' }

    },
    {
        path: '/allPharmacy',
        name: 'allPharmacy',
        component: () =>
            import ('../views/pharmacy/allPharmacy.vue'),
        meta: { name: '全部药房' }

    },
    {
        path: '/chineseMedicine',
        name: 'chineseMedicine',
        component: () =>
            import ('../views/pharmacy/chineseMedicine.vue'),
        meta: { name: '名医中药' }

    },
    {
        path: '/westernMedicine',
        name: 'westernMedicine',
        component: () =>
            import ('../views/pharmacy/westernMedicine.vue'),
        meta: { name: '综合西药' }

    },
    {
        path: '/pharmacyInfo',
        name: 'pharmacyInfo',
        component: () =>
            import ('../views/pharmacy/pharmacyInfo.vue'),
        meta: { name: '药房详情' }

    },
    {
        path: '/card',
        name: 'card',
        component: () =>
            import ('../views/pharmacy/card.vue'),
        meta: { name: '会员卡' }

    },
    {
        path: '/cardInfo',
        name: 'cardInfo',
        component: () =>
            import ('../views/pharmacy/cardInfo.vue'),
        meta: { name: '会员卡详情' }

    },
    {
        path: '/openCard',
        name: 'openCard',
        component: () =>
            import ('../views/pharmacy/openCard.vue'),
        meta: { name: '开通会员' }

    },
    {
        path: '/shoppingCar',
        name: 'shoppingCar',
        component: () =>
            import ('../views/pharmacy/shoppingCar.vue'),
        meta: { name: '购物车' }

    }
]

const router = new VueRouter({
    routes
})

export default router