import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import tim from './tim'
import TIM from 'tim-js-sdk'
import 'vant/lib/index.css';
import '@/assets/css/common.css';
import 'amfe-flexible'
import autoTextarea from './components/textarea'

window.tim = tim
window.TIM = TIM
window.store = store
Vue.prototype.$bus = new Vue() // event Bus 用于无关系组件间的通信。
Vue.prototype.tim = tim
Vue.prototype.TIM = TIM
Vue.prototype.$store = store
Vue.use(Vant);

Vue.directive('growHeight', {
    componentUpdated: function(el, bonding) {
        const grow = el.getAttribute('data-grow');
        if (grow === 'false') {
            autoTextarea(el, bonding.value);
            el.setAttribute('data-grow', 'true');
        }
    },
    inserted: function(el) {
        el.setAttribute('data-grow', 'false');
    }
});
router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.name) {
        document.title = to.meta.name
    }
    next()
})
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')