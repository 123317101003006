import axios from 'axios'
import store from '@/store'

const instance = axios.create({
    baseURL: 'http://www.rage.chnyanwei.com/',
    timeout: 3000,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        // 'authorizations': '7a9bNjnn85ynqJtNaFSuVagJ/kWo4wIylBXpNSpwkqcbi2GwAsjbtGWGQpPDNppUkcEN7HsYYlQTaaOYhTI4jdXIghmAH0CEVek2'
    },
})

instance.interceptors.request.use(
    (config) => {
        // console.log(config, 'config')
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

instance.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        return error

    }
)

export default instance