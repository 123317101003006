<template>
  <div id="app">

    <!-- <div class="loadingbox"
         v-show="isloading">
      <van-loading type="spinner" />
    </div> -->
    <router-view />
  </div>
</template>
<script>


import {login}  from '@/store/index'

export default {
  data() {
    return {
      form:{
        mobile:'18971711092',
        pwd:'123456',
        is_agree:'1',
      }
    }
  },
  computed: {
    // ...mapActions([
    //   'login'
    // ])
  },
  mounted() {
    this.$store.dispatch('login',this.form)
  },
  methods: {
      
  },
}
</script>
<style lang="less">
.mb8{
  margin-bottom: 8px;
}

.f18{
  font-size: 18px;
}
.f16{
  font-size: 16px;
}
.f14{
  font-size: 14px;
}
.f12{
  font-size: 12px;
}
.sl {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.sl1{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.h1 {
  font-size: 14px;
  font-weight: 700;
  color: #2d2d2d;
}
.em {
  font-size: 12px;
  color: rgba(194, 194, 194, 1);
}
.bg {
   background: #f5f5f5;
   min-height: 100vh;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.cl:after {
  content: "020";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.cl {
  zoom: 1;
}

.loadingbox {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 375px;
  height: 100vh;
  background: rgba(255, 255, 255, 0.8);
  .van-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
   font-size: 14px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: rgba(45,45,45,1);
  // height: 100vh;
  width: 375px;
}
</style>
